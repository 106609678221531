<template>
  <div class="dudaoBrandList">
    <van-nav-bar
      title="督导品牌列表"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-grid :column-num="3">
      <van-grid-item
        v-for="(item, index) in list"
        :key="index"
        :icon="item.thumb"
        :text="item.name"
        :to="{
          name: 'List',
          query: {
            shareid: shareid,
            merchid: merchid,
            dudaobrandId: item.id
          } 
        }"
      />
    </van-grid>
    </div>
  </div>
</template>
<script>
export default {
  name: "dudaoBrandList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      list: [],
      nodata: false
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "督导品牌列表");
    this.init();
  },
  methods: {
    init() {
      console.log("dddd");
      this.getlist();
    },
    getlist() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(_that.$store.state.domain + "web/goods/get_dudao_brand")
        .then(res => {
          console.log(res);
          _that.nodata = false;
          if (res.data.code == 100000) {
            if (res.data.data.length > 0) {
              _that.list = res.data.data
            }else{
              _that.list = [];
              _that.nodata = true;
            }
          }else{
            _that.list = [];
            _that.nodata = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
  },
}
</script>
<style lang="less">
  .dudaoBrandList {
    min-height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    .van-nav-bar .van-icon {
      color: #333;
    }
    .van-grid-item__icon .van-icon__image {
      height: 40px;
      width: 40px;
      overflow: hidden;
      border-radius: 4px;
    }
    .van-grid-item__text {
      line-height: 16px;
      height: 30px;
      font-size: 12px;
      text-align: center;
    }
  }
</style>