var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dudaoBrandList"},[_c('van-nav-bar',{attrs:{"title":"督导品牌列表","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"main"},[_c('van-grid',{attrs:{"column-num":3}},_vm._l((_vm.list),function(item,index){return _c('van-grid-item',{key:index,attrs:{"icon":item.thumb,"text":item.name,"to":{
        name: 'List',
        query: {
          shareid: _vm.shareid,
          merchid: _vm.merchid,
          dudaobrandId: item.id
        } 
      }}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }